<template>
  <agile
v-if="images && images.length > 0"
    :autoplay="true"
    :autoplay-speed="delay"
    :infinite="true"
    :fade="true"
    :options="carouselOptions"
>
    <div v-for="(image, i) in images" :key="i" class="slide" :class="'slide--' + i">
      <a v-if="image.carousel_image_link && image.carousel_image_link.url && image.carousel_image_link.url.length > 0" :href="image.carousel_image_link.url">
        <base-image
              :source="image.carousel_image.url | fullImagePath('F')"
              :alt="'Baker Book House Banner Image'"
              />
      </a>
      <base-image
v-else
              :source="image.carousel_image.url | fullImagePath('F')"
              :alt="'Baker Book House Banner Image'"
              />
</div>
  </agile>
</template>

<script>
export default {
  name: 'Carousel',
  data () {
    return {
      carouselOptions: {
        navButtons: false,
        dots: false,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              navButtons: true,
              dots: true
            }
          }
        ]
      }
    }
  },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    delay: {
      type: Number,
      default: 8000
    }
  }
}
</script>

<style lang="sass">
  // Basic VueAgile styles pulled from their site
  // https://github.com/lukaszflorczak/vue-agile
  .agile

    &__actions
      padding-left: 20px
      padding-right: 20px
      padding-bottom: 20px
      position: absolute
      width: 100%
      bottom: -10px

    &__nav-button
      background: transparent
      border: none
      color: #fff
      cursor: pointer
      font-size: 24px
      transition-duration: .3s

      &:hover
        color: #888

    &__dot
      margin: 0 10px

      button
        background-color: #888
        border: none
        border-radius: 50%
        cursor: pointer
        display: block
        height: 10px
        font-size: 0
        line-height: 0
        margin: 0
        padding: 0
        transition-duration: .3s
        width: 10px

      &--current,
      &:hover
        button
          background-color: #fff

  // Slides styles
  .slide
    display: block
    object-fit: cover
    width: 100%
</style>
